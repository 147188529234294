
import api from '@/api'
import handleLocationInfo from '@/hooks/coordinateHooks'
import { IObject } from '@/types/inerface'
import { AxiosRequestConfig } from 'axios'
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup () {
    const router = useRouter()
    const store = useStore()
    let state = reactive({
      stationInfo: [],
      latitude: 0,
      longitude: 0
    })
    handleLocationInfo((position) => {
      state.latitude = position.coords.latitude;
      state.longitude = position.coords.longitude;
      api.bus.nearSta({
        lat: state.latitude,
        lng: state.longitude,
      }).then((res: AxiosRequestConfig["data"]) => {
        if (res?.code === 0) {
          // 缓存定位
          store.commit('coordinate', {
            latitude: state.latitude,
            longitude: state.longitude
          })
          state.stationInfo = res.data;
        }
      });
    })

    const handleToBusList = async (station: IObject) => {
      if (store.state.city.id === '') {
        await store.dispatch('handleFindCity')
        console.log('123')
      }
      // const cityId = store.state.city.id
      const busListStr = station.busList.join(',')
      const staName = station.staName
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      
      // if (!cityId) {
      //   const res = await api.bus.locationToCity({
      //     lat: state.latitude,
      //     lng: state.longitude
      //   })
      //   if (res?.code === 0) {
      //     store.commit('currentCity', {
      //       city: res.data.city,
      //       id: res.data.id
      //     })
      //   }
      // }
      router.push({
        path: '/busList',
        query: {
          staName,
          busListStr,
          cityId: store.state.city.id
        }
      })
    }

    Toast.loading({
      duration: 500,
      message: '加载中...',
      forbidClick: true,
    });
    return {
      handleToBusList,
      ...toRefs(state)
    }
  },
})
