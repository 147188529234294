<template>
  <div class="content">
    <van-nav-bar
      title="附近车站"
      fixed
      safe-area-inset-top
    />
    <div class="station" v-for="item in stationInfo" :key="item.staName" @click="handleToBusList(item)">
      <p class="name">
        <span class="title">
          <i class="iconfont icon-gongjiaozhanpai"></i>
          {{item.staName}} [公交站]
        </span>
        <span class="distance">
          约{{item.distance}}米
        </span>
      </p>
      <p class="busList">
        <span class="bus" v-for="(bus, index) in item.busList" :key="bus">
          {{bus}}<span v-if='index < [item.busList.length - 1]'>，</span>
        </span>
      </p>
    </div>
    <div class="tips small">部分数据来源：高德实时公交</div>
  </div>
</template>

<script lang="ts">
import api from '@/api'
import handleLocationInfo from '@/hooks/coordinateHooks'
import { IObject } from '@/types/inerface'
import { AxiosRequestConfig } from 'axios'
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup () {
    const router = useRouter()
    const store = useStore()
    let state = reactive({
      stationInfo: [],
      latitude: 0,
      longitude: 0
    })
    handleLocationInfo((position) => {
      state.latitude = position.coords.latitude;
      state.longitude = position.coords.longitude;
      api.bus.nearSta({
        lat: state.latitude,
        lng: state.longitude,
      }).then((res: AxiosRequestConfig["data"]) => {
        if (res?.code === 0) {
          // 缓存定位
          store.commit('coordinate', {
            latitude: state.latitude,
            longitude: state.longitude
          })
          state.stationInfo = res.data;
        }
      });
    })

    const handleToBusList = async (station: IObject) => {
      if (store.state.city.id === '') {
        await store.dispatch('handleFindCity')
        console.log('123')
      }
      // const cityId = store.state.city.id
      const busListStr = station.busList.join(',')
      const staName = station.staName
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      
      // if (!cityId) {
      //   const res = await api.bus.locationToCity({
      //     lat: state.latitude,
      //     lng: state.longitude
      //   })
      //   if (res?.code === 0) {
      //     store.commit('currentCity', {
      //       city: res.data.city,
      //       id: res.data.id
      //     })
      //   }
      // }
      router.push({
        path: '/busList',
        query: {
          staName,
          busListStr,
          cityId: store.state.city.id
        }
      })
    }

    Toast.loading({
      duration: 500,
      message: '加载中...',
      forbidClick: true,
    });
    return {
      handleToBusList,
      ...toRefs(state)
    }
  },
})
</script>


<style lang="less" scoped>
.content {
  padding-top: 88px;
  padding-bottom: 160px;
  background: #EEE;
}
.station {
  font-size: 32px;
  padding: 16px;
  border-bottom: 2px solid #ddd;
  color: #333;
  background: #fff;
  .name {
    margin: 16px 0;
    display: flex;
    i {
      font-size: 36px;
      padding: 0 8px;
    }
    .title {
      flex: 1;
      .iconfont {
        font-weight: bold;
        color: #1989fa;
      }
    }
    .distance {
      font-size: 24px;
      color: rgb(128, 128, 128);
    }
  }
  .busList {
    margin: 8px 0;
    padding: 0 16px 0 16px;
    font-size: 26px;
    color: rgb(128, 128, 128);
    .ellipse();
  }
}

.tips {
  padding: 8px;
  background: @base-background-color;
  text-align: center;
  font-size: 24px;
  color: rgb(117, 117, 117);
  &.small {
    margin-top: 32px;
    font-size: 24px;
  }
}
</style>
