import { GeolocationPositionError } from "@/types/inerface";
import { Toast } from 'vant';
// import { reactive } from 'vue'

// const state = reactive({
//   latitude: 0,
//   longitude: 0
// })

// const handleShowPosition = (position: position) => {
//   state.latitude = position.coords.latitude;
//   state.longitude = position.coords.longitude;
// };

const handleOnError = (error: GeolocationPositionError) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      Toast.fail("您拒绝对获取地理位置的请求");
      break;
    case error.POSITION_UNAVAILABLE:
      Toast.fail("位置信息是不可用的");
      break;
    case error.TIMEOUT:
      Toast.fail("请求您的地理位置超时");
      break;
  }
};
const handleLocationInfo = (handleShowPosition: PositionCallback):void => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      handleShowPosition,
      handleOnError,
      {
        // 指示浏览器获取高精度的位置，默认为false
        enableHighAccuracy: true,
        // 指定获取地理位置的超时时间，默认不限时，单位为毫秒
        // timeout: 5000,
        // 最长有效期，在重复获取地理位置时，此参数指定多久再次获取位置。
        // maximumAge: 3000,
      }
    );
  } else {
    Toast.fail("浏览器或手机不支持位置信息");
  }
};

export default handleLocationInfo